@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'CustomFont'; 
  src: url('https://portfolio-elisa-2023.s3.eu-west-1.amazonaws.com/fonts/GT-Maru-Mono-Regular-Trial.otf') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'CustomFont', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
